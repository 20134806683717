
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /*
| Developed by Starton
| Filename : 404.tsx
| Author : Calixte De Tourris (calixte@starton.com)
*/
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { StartonPage } from '../contracts/next-layout';
import { ErrorPageTemplate } from '@/components/templates/ErrorPageTemplate/ErrorPageTemplate';
/*
|--------------------------------------------------------------------------
| Page
|--------------------------------------------------------------------------
*/
const NotFound: StartonPage = () => {
    const { t } = useTranslation();
    // Render
    //--------------------------------------------------------------------------
    return (<ErrorPageTemplate showBackToDashboardButton code={'404'} messageTitle={t('errors:404.title')} charFace={'〜(￣△￣〜)'} color={'warning'}/>);
};
NotFound.layout = 'empty';
export default NotFound;

    async function __Next_Translate__getStaticProps__19485873627__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19485873627__ as getStaticProps }
  